define("discourse/plugins/discourse-follow/discourse/components/follow-post-stream", ["exports", "@ember/component", "discourse/components/user-stream", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _userStream, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each stream.content as |post|}}
    {{follow-feed-post post=post}}
  {{/each}}
  */
  {
    "id": "Kh66NvGW",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"stream\",\"content\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"post\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,[28,[32,0],[\"[[\\\"The `stream` property path was used in the `discourse/plugins/discourse-follow/discourse/components/follow-post-stream.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.stream}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"post\"],false,[\"each\",\"-track-array\",\"follow-feed-post\"]]",
    "moduleName": "discourse/plugins/discourse-follow/discourse/components/follow-post-stream.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  class FollowPostStream extends _userStream.default {}
  _exports.default = FollowPostStream;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FollowPostStream);
});